html,
body {
    background-color: hsl(245, 30%, 20%);
    color: #fff;
    /* cursor: none !important; */
    cursor: url(./pages/images/cursor.svg) 6 6, auto !important;
    /* change cursor outline */
}

.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right !important;
}

.modal-header .close {
    color: #fff;
    opacity: 1;
}

.App {
    text-align: center;
    background-color: hsl(245, 30%, 20%);
    color: #fff;
    margin-top: 10vh;
}

.blue-bg {
    background-color: hsl(245, 30%, 20%);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: hsl(245, 30%, 20%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #ffffff;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.hover-zoom:hover {
    transform: scale(1.05); /* Increase the size on hover */
    transition: transform 0.3s ease; /* Add a smooth transition effect */
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
}

.hover-shadow:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.mask-custom {
    backdrop-filter: blur(5px);
    background-color: hsl(245, 30%, 20%, 0.15);
}
.modal-content {
    /* this has all content of modal content class with edits */
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    /* background-color: var(--bs-modal-bg); */
    background-clip: padding-box;
    /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    backdrop-filter: blur(5px) !important;
    background-color: hsl(245, 30%, 20%, 0.5) !important;
}

h1 {
    font-size: calc(2rem + 2.5vw) !important;
    font-weight: 800 !important;
}

h2 {
    font-size: calc(1.5rem + 1.9vw) !important;
    font-weight: 800 !important;
}

.zIndex1 {
    z-index: -30 !important;
}
.zIndex2 {
    z-index: 4 !important;
}
.zIndex3 {
    z-index: 5 !important;
    /* color:"#2e426b80", "#2e336b80", "#332e6b80", "#422e6b80"; */
}

.modal-css h3 {
    font-size: calc(1.5rem + 1.7vw) !important;
    font-weight: 800 !important;
}

/* screen size ipad */
@media screen and (min-width: 768px) {
    .precious-img {
        height: 20vh !important;
        width: 20vh !important;
    }

    /* bg */

    .container_foto {
        background-color: rgba(57, 62, 93, 0.7);
        padding: 0;
        overflow: hidden;
        max-width: 350px;
        margin: 5px;
        position: relative;
        width: 18rem;
        max-width: 30vw;
        height: 27rem;
        max-height: calc(30vw * 1.5);
    }

    .container_foto article {
        /* padding: 1; */
        width: 100%;
        position: absolute;
        height: 100%;
        bottom: 0;
        z-index: 1;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .container_foto h2 {
        color: #fff;
        font-weight: 800;
        font-size: calc(1.2rem + 1.5vw) !important;
        border-bottom: #fff solid 1px;
    }

    .container_foto h4 {
        font-weight: 300;
        color: #fff;
        font-size: 16px;
    }

    .container_foto img {
        width: 100%;
        height: 100%;
        /* top: 0;
        left: 0; */
        opacity: 0.5;
        -webkit-transition: all 4s ease;
        -moz-transition: all 4s ease;
        -o-transition: all 4s ease;
        -ms-transition: all 4s ease;
        transition: all 4s ease;
    }

    .ver_mas {
        background-color: hsl(245, 40%, 30%);
        position: absolute;
        width: 100%;
        height: 60%;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        transform: translate(0px, 60%);
        -webkit-transform: translate(0px, 60%);
        -moz-transform: translate(0px, 60%);
        -o-transform: translate(0px, 60%);
        -ms-transform: translate(0px, 60%);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .ver_mas span {
        /* font-size: 40px; */
        color: #fff;
        position: relative;
        margin: 0 auto;
        width: 90%;
        top: 13px;
    }

    /*hovers*/

    h1 {
        color: #fcfbfa;
    }

    .contenedor {
        height: 100%;
        padding: 5% 0;
    }

    /* .container_foto:hover {
    cursor: pointer;
  } */

    .container_foto:hover img {
        opacity: 0.1;
        transform: scale(1.5);
    }

    .container_foto:hover article {
        transform: translate(1%, -28%);
        -webkit-transform: translate(1%, -28%);
        -moz-transform: translate(1%, -28%);
        -o-transform: translate(1%, -28%);
        -ms-transform: translate(1%, -28%);
    }

    .container_foto:hover .ver_mas {
        transform: translate(0px, 0px);
        -webkit-transform: translate(0px, 0px);
        -moz-transform: translate(0px, 0px);
        -o-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .precious-img {
        height: 15vh !important;
        width: 15vh !important;
    }

    /* bg */

    .container_foto {
        background-color: rgba(57, 62, 93, 0.7);
        padding: 0;
        overflow: hidden;
        max-width: 350px;
        margin: 5px;
        position: relative;
        width: 18rem;
        /* max-width: 20vw; */
        height: 27rem;
        /* max-height: calc(30vw * 1.5); */
    }

    .container_foto article {
        /* padding: 1; */
        width: 100%;
        position: absolute;
        height: 100%;
        bottom: 0;
        z-index: 1;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .container_foto h2 {
        color: #fff;
        font-weight: 800;
        font-size: calc(1.2rem + 1.5vw) !important;
        border-bottom: #fff solid 1px;
    }

    .container_foto h4 {
        font-weight: 300;
        color: #fff;
        font-size: 16px;
    }

    .container_foto img {
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0.4;
        -webkit-transition: all 4s ease;
        -moz-transition: all 4s ease;
        -o-transition: all 4s ease;
        -ms-transition: all 4s ease;
        transition: all 4s ease;
    }

    .ver_mas {
        background-color: hsl(245, 40%, 30%);
        position: absolute;
        width: 100%;
        height: 60%;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        transform: translate(0px, 60%);
        -webkit-transform: translate(0px, 60%);
        -moz-transform: translate(0px, 60%);
        -o-transform: translate(0px, 60%);
        -ms-transform: translate(0px, 60%);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .ver_mas span {
        /* font-size: 40px; */
        color: #fff;
        position: relative;
        margin: 0 auto;
        width: 90%;
        top: 13px;
    }
}
